<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="SunsetIcon"/>
              <span>Form Bilgileri</span>
            </template>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İzin Tipi" rules="required">
                  <b-form-group label="* İzin Tipi" :state="getValidationState(validationContext)">
                    <v-select v-model="leaveTypeId" :clearable="false" :options="leaveTypeOptions" label="label" :reduce="val => val.value">
                      <template #option="{ description, label }">
                        <feather-icon icon="InfoIcon" size="16" v-b-tooltip.hover.bottom="`${description}`"/>
                        <span class="ml-50 align-middle"> {{ label }}</span>
                      </template>
                      <template #selected-option="{ avatar, label }">
                        <span class="ml-50 align-middle"> {{ label }}</span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6" v-if="bulkForm">
                <validation-provider #default="validationContext" name="Onay Süreci Kontrolü Atlansın">
                  <b-form-group label="Onay Süreci Kontrolü Atlansın" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.bypassWorkflowCheck" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="CalendarIcon" size="16"/>
              <h5 class="mb-0 ml-50">İzin Tarihleri</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Başlangıç Tarihi" rules="required">
                  <b-form-group label="* Başlangıç Tarihi" :state="getValidationState(validationContext)">
                    <flatpickr v-model="startDate" class="form-control" :config="{enableTime: true, defaultHour: 8, defaultMinute: 0, minuteIncrement: 30, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1, minDate: minStartDate}"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Bitiş Tarihi" rules="required">
                  <b-form-group label="* Bitiş Tarihi" :state="getValidationState(validationContext)">
                    <flatpickr v-model="endDate" class="form-control" :config="{enableTime: true, defaultHour: 18, defaultMinute: 0, minuteIncrement:30, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1, minDate: minEndDate}"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İşe Başlama Tarihi" rules="required">
                  <b-form-group label="* İşe Başlama Tarihi" :state="getValidationState(validationContext)">
                    <flatpickr v-model="dataInfo.returnDate" class="form-control" :config="{enableTime: true, defaultHour: 18, defaultMinute: 0, minuteIncrement:30, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1, minDate: minEndDate}"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6" class="d-flex align-items-center">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-primary" size="34">
                      <feather-icon icon="CalendarIcon" size="18"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="mb-0">Toplam Gün: {{ leaveDurationTotalDays }}</h6>
                    <small>Kalan Gün: {{ leaveRemainingDays }}</small>
                  </b-media-body>
                </b-media>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Yerine Bakacak Kişi">
                  <b-form-group label="Yerine Bakacak Kişi" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.alternativeUserId" :clearable="false" :options="alternativeUserOptions" label="label" :reduce="val => val.value">
                      <template #option="{ avatar, label }">
                        <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                        <span class="ml-50 align-middle"> {{ label }}</span>
                      </template>
                      <template #selected-option="{ avatar, label }">
                        <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                        <span class="ml-50 align-middle"> {{ label }}</span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İzinde Ulaşılacak Telefon">
                  <b-form-group label="İzinde Ulaşılacak Telefon">
                    <b-form-input trim placeholder="İzinde Ulaşılacak Telefon" v-model="dataInfo.phoneOnLeave" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İzinde Olacağı Adres">
                  <b-form-group label="İzinde Olacağı Adres">
                    <b-form-textarea trim placeholder="İzinde Olacağı Adres" v-model="dataInfo.addressOnLeave" :state="getValidationState(validationContext)" rows="2"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="2"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6" v-if="!bulkForm">
                <validation-provider #default="validationContext" name="Döküman">
                  <b-form-group label="Döküman" description="Birden fazla döküman seçimi yapabilirsiniz...">
                    <b-form-file id="leaveFiles" name="leaveFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

          </b-tab>

          <b-tab v-if="bulkForm">
            <template #title>
              <feather-icon icon="UsersIcon"/>
              <span>Çalışan Bilgileri</span>
            </template>

            <b-alert variant="warning" show class="mb-1">
              <div class="alert-body font-small-2">
                <p><small class="mr-50"><span class="font-weight-bold">Sadece seçilen çalışanlar için kayıt oluşturulacaktır.</span></small></p>
              </div>
            </b-alert>

            <b-button variant="outline-secondary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="selectRecords(true)">
              <feather-icon icon="CheckIcon" size="16"/>
              <span class="align-middle"> Tümünü Seç</span>
            </b-button>
            <b-button variant="outline-secondary" @click="selectRecords(false)">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Tümünü Kaldır</span>
            </b-button>

            <b-card no-body class="border mt-1">
              <b-table
                  :items="dataInfo.users"
                  :fields="userFields"
                  class="mb-0"
                  striped responsive bordered hover>
                <template #cell(profilePicture)="data">
                  <b-avatar
                      ref="previewEl"
                      :src="getApiFile(data.value)"
                      :text="avatarText(data.item.name)"
                      :variant="`light-primary`"
                      size="30px"
                  />
                </template>
                <template #cell(selection)="data">
                  <b-form-checkbox :checked="data.value" @change="changeSelectUserValue(data.item)"/>
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
        <action-buttons :back-route="'form-leave-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, VBPopover, VBTooltip, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BMedia, BMediaAside, BMediaBody, BFormFile, BAlert, BTable, BButton
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref, watch} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@core/utils/validations/validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/form/leave/store"
import leaveTypeModule from "@/views/definition/leavetype/store"
import userModule from "@/views/organization/employee/store"
import flatpickr from "vue-flatpickr-component/src/component"

export default {
  components: {
    BButton,
    BTable, BAlert,
    BFormFile,
    BMediaBody,
    BMediaAside,
    BMedia,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,
    VBPopover,
    VBTooltip,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  setup() {

    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'
    const STORE_LEAVE_TYPE_MODULE_NAME = 'store-leavetype'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LEAVE_TYPE_MODULE_NAME, leaveTypeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LEAVE_TYPE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      userId: JSON.parse(localStorage.getItem('userData')).id,
      leaveTypeId: null,
      startDate: null,
      endDate: null,
      returnDate: null,
      description: '',
      addressOnLeave: '',
      phoneOnLeave: '',
      alternativeUserId: null,
      bypassWorkflowCheck: false,
      users: []
    })

    const leaveDurationTotalDays = ref(0)
    const leaveDurationTotalHours = ref(0)
    const leaveRemainingDays = ref(0)

    const startDate = ref(null)
    const endDate = ref(null)
    const leaveTypeId = ref(null)
    const bulkForm = ref(false)

    watch([startDate, endDate, leaveTypeId], () => {
      if (startDate.value !== null && endDate.value !== null && leaveTypeId.value !== null) {
        busy.value = true
        store.dispatch('store/durationCalculate', {startDate: startDate.value, endDate: endDate.value, leaveTypeId: leaveTypeId.value, userId: dataInfo.value.userId}).then(response => {
          leaveDurationTotalDays.value = response.data.data.totalDays
          leaveDurationTotalHours.value = response.data.data.totalHours
          leaveRemainingDays.value = response.data.data.remainingDays
          dataInfo.value.returnDate = response.data.data.returnDate
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const leaveTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-leavetype/fetchItems', {
      userId: dataInfo.value.userId,
      filters: true,
      sortColumn: 'sortNumber'
    }).then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          leaveTypeOptions.value.push({label: value.name, value: value.id, description: value.description ?? ''})
        }
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.leaveTypeId = value.id
          leaveTypeId.value = value.id
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      dataInfo.value.startDate = startDate.value
      dataInfo.value.endDate = endDate.value
      dataInfo.value.leaveTypeId = leaveTypeId.value

      const formData = new FormData();

      if (!bulkForm.value) {
        const documents = document.getElementById('leaveFiles').files
        for (let i = 0; i < documents.length; i++) {
          let file = documents[i];
          formData.append('documents[' + i + ']', file);
        }
      }

      formData.append('id', dataInfo.value.id);
      formData.append('userId', dataInfo.value.userId);
      formData.append('leaveTypeId', dataInfo.value.leaveTypeId);
      formData.append('startDate', dataInfo.value.startDate);
      formData.append('endDate', dataInfo.value.endDate);
      formData.append('returnDate', dataInfo.value.returnDate);
      if (dataInfo.value.description !== null) {
        formData.append('description', dataInfo.value.description);
      }
      if (dataInfo.value.addressOnLeave !== null) {
        formData.append('addressOnLeave', dataInfo.value.addressOnLeave);
      }
      if (dataInfo.value.phoneOnLeave !== null) {
        formData.append('phoneOnLeave', dataInfo.value.phoneOnLeave);
      }
      if (dataInfo.value.alternativeUserId !== null) {
        formData.append('alternativeUserId', dataInfo.value.alternativeUserId);
      }
      formData.append('bypassWorkflowCheck', dataInfo.value.bypassWorkflowCheck);

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : (bulkForm.value ? 'bulkItem' : 'addItem')), (bulkForm.value ? dataInfo.value : formData)).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'form-leave-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        startDate.value = dataInfo.value.startDate
        endDate.value = dataInfo.value.endDate
        leaveTypeId.value = dataInfo.value.leaveTypeId
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'form-leave-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    } else {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      bulkForm.value = params.get("bulk") === 'true';
      if (bulkForm.value) {
        dataInfo.value.bypassWorkflowCheck = true;
      }
    }

    const alternativeUserOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {
      sortColumn: 'firstName',
      sortDesc: false,
      isActive: true,
      isSelection: true,
    }).then(response => {
      response.data.data.forEach((value, index) => {
        if (!alternativeUserOptions.value.some(e => e.value === value.id)) {
          alternativeUserOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (bulkForm.value) {
      busy.value = true
      store.dispatch('store-user/fetchItems', {
        sortColumn: 'firstName',
        sortDesc: false,
        isActive: true,
      }).then(response => {
        response.data.data.forEach((value, index) => {
          if (!dataInfo.value.users.some(e => e.userId === value.id)) {
            dataInfo.value.users.push({
              userId: value.id,
              name: value.firstName + ' ' + value.lastName,
              profilePicture: value.profilePicture,
              selection: false,
            })
          }
        })
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    var pastDay =  JSON.parse(localStorage.getItem('employeeData')).settings.filter(function(creature) {
      return creature.key === "PastDay";
    })[0].value;

    var minStartDate = new Date();
    minStartDate.setDate(minStartDate.getDate() - parseInt(pastDay));
    minStartDate.setHours(8, 0, 0)

    var minEndDate = new Date();
    minEndDate.setDate(minEndDate.getDate() - parseInt(pastDay));
    minEndDate.setHours(18, 0, 0)

    const userFields = [
      {key: 'profilePicture', label: 'Profil', thStyle: {width: "5%"}},
      {key: 'name', label: 'Ad Soyad'},
      {key: 'selection', label: 'Seçim', thStyle: {width: "10%"}}
    ];

    const changeSelectUserValue = (target) => {
      dataInfo.value.users.forEach(function callback(value, index) {
        if (value.userId === target.userId) {
          value.selection = !target.selection
        }
      })
    }

    const selectRecords = (selection) => {
      dataInfo.value.users.forEach(function callback(value, index) {
        value.selection = selection
      })
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      alternativeUserOptions,
      leaveTypeOptions,
      leaveDurationTotalDays,
      leaveDurationTotalHours,
      startDate,
      endDate,
      leaveTypeId,
      leaveRemainingDays,
      minStartDate,
      minEndDate,
      bulkForm,
      userFields,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      changeSelectUserValue,
      selectRecords,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>